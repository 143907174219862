import React from 'react';

const Logo = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="60px"
    height="60px"
    viewBox="0 0 567.863 567.863"
    xmlSpace="preserve"
  >
    <g>
      <path d="M283.931,0C127.118,0,0,127.119,0,283.931c0,156.813,127.118,283.932,283.931,283.932s283.932-127.119,283.932-283.932
			C567.863,127.119,440.744,0,283.931,0z M486.785,407.451c-5.172,3.385-10.992,5.006-16.738,5.006
			c-9.977,0-19.75-4.871-25.625-13.838l-10.992-16.768l-34.082-52.02H168.514l-34.083,52.02l-10.985,16.768
			c-9.259,14.133-28.219,18.086-42.362,8.826c-14.137-9.266-18.091-28.232-8.825-42.369L258.343,81.078
			c5.655-8.629,15.276-13.831,25.594-13.831c10.319,0,19.94,5.202,25.594,13.831l186.078,284.004
			C504.875,379.213,500.922,398.186,486.785,407.451z" />
      <polygon points="208.612,268.631 359.25,268.631 283.931,153.685 		" />
    </g>
  </svg>
);

export default Logo;
